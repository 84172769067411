import React, { useState } from "react";
import { Button, Loader, MarkdownEditorField } from "core/components";
import {
  NurtureAIFeedback,
  CurriculumBoundarySwitch,
  referencesText,
  NoMatchModal,
  CurriculumBoundaryReferences,
} from "core/components/nurtureAI";
import { SelectField, TextInput } from "core/form/fields";
import { FormGroup } from "reactstrap";
import PropTypes from "prop-types";
import { useSchoolConfig } from "core/hooks";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { generateAssignment } from "./action";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ASSESSMENT_TYPE_OPTIONS, QUESTION_COUNT_OPTIONS } from "./static";
import "react-range-slider-input/dist/style.css";
import { v4 as uuidv4 } from "uuid";
import {faAngleDown, faAngleRight, faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import { isPresent, pluralize } from "core/utils";
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import ConfirmModal from "../modals/Confirm";
import metricService from "../../../lib/metricService";
import {resetStartTime, setStartTime, streamResponse, trackDuration} from "./helpers";
import env_variables from "core/utils/env_variables";
import MultiSelect from "core/form/fields/MultiSelect";

const NurtureAIAssignmentGenerator = ({ onSuggest, extras }) => {
  const STATES = { showHint: 1, working: 2, suggestion: 3 };
  const LOADING_TEXTS = [
    "Making coffee",
    "Generating Assessment",
    "Almost done",
  ];

  const [state, setState] = useState(STATES.showHint);
  const [hint, setHint] = useState("");
  const [questionCount, setQuestionCount] = useState(3);
  const [showAdvancedFields, setShowAdvancedFields] = useState(false);
  const [suggestedName, setSuggestedName] = useState("");
  const [suggestedDescription, setSuggestedDescription] = useState("");
  const [suggestedLearningObjectives, setSuggestedLearningObjectives] =
    useState([]);
  const [extraTexts, setExtraTexts] = useState("");
  const [references, setReferences] = useState({});
  const [aiRequestId, setAiRequestId] = useState(null);
  const [loadingText, setLoadingText] = useState(LOADING_TEXTS[0]);
  const [showNoMatchModal, setShowNoMatchModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [asssementTypes, setAssessmentTypes] = useState([]);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const {
    learningObjectiveLabel,
    channelOnboarding,
    curriculumBoundaryEnabled: curriculumBoundaryFeatureEnabled,
  } = useSchoolConfig();
  const curriculumAttachments = channelOnboarding?.data?.curriculum_attachments;
  const embeddings_completed = channelOnboarding?.data?.embeddings_completed;
  const [curriculumBoundaryEnabled, setCurriculumBoundaryEnabled] = useState(
    isPresent(curriculumAttachments) &&
      embeddings_completed &&
      curriculumBoundaryFeatureEnabled
  );

  const generateSuggestionsAsync = async () => {
    setState(STATES.working);
    const intervalId = toggleLoadingText();

    try {

      const requestBody = {
        hint,
        question_count: questionCount,
        // assessment_types: asssementTypes.join(', '),
        curriculumn_boundary_enabled: curriculumBoundaryEnabled,
        run_in_background: true,
        ...extras,
      };

      setStartTime();
      const response = await dispatch(generateAssignment(requestBody))
      const threadId = response.thread_id;

      streamResponse(`thread_id=${threadId}&assignment=true`, handleStreamEvent);

      clearInterval(intervalId);
    } catch (e) {
      resetStartTime();
      enqueueSnackbar(e.message, { variant: 'error' });
      setState(STATES.showHint)
      metricService.track({ event: 'ai_failed', properties: { scope: 'assignment_generation' } });

      clearInterval(intervalId);
    }
  };

  const handleStreamEvent = async (event) => {
    const messageEvent = JSON.parse(event.data);

    switch (messageEvent.event) {
      case 'assistant.assessment':
        handleAiSuggestion(messageEvent.data);
        break;

      case 'thread.error':
        handleErrorEvent(messageEvent);
        break;

      case 'thread.run.failed':
        handleErrorEvent(messageEvent);
        break;
    }
  }

  const handleErrorEvent = (event) => {
    enqueueSnackbar('Error occurred while generating assessment', { variant: 'error' });
    setState(STATES.showHint)
    resetStartTime();
    metricService.track({ event: 'ai_failed', properties: { scope: 'assignment_generation' } });
  }

  const generateSuggestion = generateSuggestionsAsync;

  const handleAiSuggestion = (aggregatedData) => {
    setSuggestedName(aggregatedData.title || aggregatedData.Title);
    setSuggestedDescription(
      aggregatedData.description || aggregatedData.Description
    );
    setSuggestedLearningObjectives(
      (prevState) =>
        aggregatedData.learningObjectives ||
        aggregatedData.learning_objectives ||
        aggregatedData["learning-objectives"] ||
        aggregatedData["learning objectives"] ||
        aggregatedData["Learning Objectives"] ||
        aggregatedData["Learning_Objectives"] ||
        aggregatedData["objectives"] ||
        aggregatedData["Objectives"]
    );
    setAiRequestId(aggregatedData.id);
    if (aggregatedData.no_curriculum_match) {
      setShowNoMatchModal(true);
      setState(STATES.showHint);
    } else if (isPresent(aggregatedData.references)) {
      setReferences(() => aggregatedData.references);
      setExtraTexts(
        `References: pages ${referencesText(
          aggregatedData.references
        )} from the uploaded document`
      );
      setState(STATES.suggestion);
    } else {
      setState(STATES.suggestion);
    }

    trackDuration('assignment_generation');
    metricService.track({ event: 'ai_success', properties: { scope: 'assignment_generation' } });
  }

  const toggleLoadingText = () => {
    setLoadingText(LOADING_TEXTS[0]);
    let currentIndex = 0;
    return setInterval(() => {
      if (currentIndex < LOADING_TEXTS.length - 1) {
        setLoadingText(LOADING_TEXTS[currentIndex + 1]);
        currentIndex += 1;
      }
    }, 3000);
  };

  const handleHintChange = (value) => setHint(value);
  const handleQuestionCountChanged = (e) => setQuestionCount(e.target.value);
  const handleAssessmentTypeChange = (selected) => {
    setAssessmentTypes(selected.map((option) => option.label));
  }
  const handleAdvancedFieldsToggle = () =>
    setShowAdvancedFields(!showAdvancedFields);

  const handleNameChange = (e) => setSuggestedName(e.target.value);
  const handleDescriptionChange = (value) => setSuggestedDescription(value);
  const handleLearningObjectiveChange = (index, e) => {
    const objectives = [...suggestedLearningObjectives];
    objectives[index] = e.target.value;

    setSuggestedLearningObjectives((prevState) => objectives);
  };

  const handleCurriculumBoundaryChanged = (e) => {
    setCurriculumBoundaryEnabled(!curriculumBoundaryEnabled);
  };

  const handleSuggest = () => {
    if (typeof onSuggest === "function") {
      const result = {
        name: suggestedName,
        description: suggestedDescription,
        learningObjectives: suggestedLearningObjectives,
      };

      onSuggest(result);
    }
  };

  const tryAgain = () => {
    setState(STATES.showHint);
    setHint("");
    setExtraTexts("");
    setQuestionCount(3);
    setAssessmentTypes(() => []);
    setShowAdvancedFields(false);
    setShowNoMatchModal(false);
    setReferences([]);
  };

  return (
    <div>
      {state === STATES.showHint && (
        <div>
          <div className="px-3">
            <FormGroup>
              <label className="form-label">
                What you like an assessment about?
              </label>
              <div className="mb-3">
                <MarkdownEditorField
                  className="markdown"
                  initialValue={hint}
                  maxHeight="100px"
                  onChange={handleHintChange}
                />
              </div>
            </FormGroup>

            {curriculumBoundaryFeatureEnabled && (
              <>
                <div className="mb-3 mt-4">
                  <CurriculumBoundarySwitch
                    checked={curriculumBoundaryEnabled}
                    onCurriculumBoundarySwitchChanged={
                      handleCurriculumBoundaryChanged
                    }
                  />
                </div>

                <NoMatchModal
                  show={showNoMatchModal}
                  onContinue={() => setState(STATES.suggestion)}
                  closeModal={() => setShowNoMatchModal(false)}
                />
              </>
            )}

            {/* Advanced Fields Toggle */}
            <div className="text-muted font-size-14 d-flex mb-3">
              <div
                className="d-flex align-items-center cursor-pointer text-decoration-underline"
                onClick={handleAdvancedFieldsToggle}
              >
                <span>Advanced Settings</span>
                <FontAwesomeIcon
                  className="ms-1"
                  icon={showAdvancedFields ? faAngleDown : faAngleRight}
                />
              </div>
            </div>

            {/*Advanced Fields*/}
            {showAdvancedFields && (
              <div className="px-1 px-sm-3 py-2 bg-light rounded">
                {/* Question Count */}
                <div className="mb-4">
                  <SelectField
                    className="default"
                    label={`Number of Questions`}
                    options={QUESTION_COUNT_OPTIONS}
                    value={questionCount}
                    onChange={handleQuestionCountChanged}
                  />
                </div>

                {/* Assessment Type */}
                {/*<div className="mb-3">*/}
                {/*  <MultiSelect*/}
                {/*    options={ASSESSMENT_TYPE_OPTIONS}*/}
                {/*    selectedOptions={[]}*/}
                {/*    onChange={handleAssessmentTypeChange}*/}
                {/*    label="Assessment Type"*/}
                {/*  />*/}
                {/*</div>*/}
              </div>
            )}
          </div>
          <div className="d-grid border-top p-3 px-3 mt-3">
            <Button disabled={!hint} onClick={generateSuggestion}>
              Generate Assessment ✨
            </Button>
          </div>
        </div>
      )}

      {state === STATES.working && (
        <div className="py-4 px-3">
          <div className="d-flex justify-content-center mb-3">
            <Loader type="ring" loading={true} color="#6CBDBF" />
          </div>
          <div className="font-size-15 text-muted mb-3 text-center">
            {loadingText}...
          </div>
        </div>
      )}

      {state === STATES.suggestion && (
        <div>
          <div className="px-3">
            <FormGroup>
              <TextInput
                label="Assessment Name"
                value={suggestedName}
                onChange={handleNameChange}
              />
            </FormGroup>

            <div className="mb-3">
              <label className="form-label">Description</label>
              <div>
                <MarkdownEditorField
                  className="markdown"
                  initialValue={suggestedDescription}
                  maxHeight="100px"
                  onChange={handleDescriptionChange}
                />
              </div>
            </div>

            {suggestedLearningObjectives?.length > 0 && (
              <label className="form-label">{learningObjectiveLabel}</label>
            )}
            {suggestedLearningObjectives?.map((value, index) => {
              return (
                <FormGroup>
                  <TextInput
                    value={value}
                    onChange={(e) => handleLearningObjectiveChange(index, e)}
                  />
                </FormGroup>
              );
            })}

            {!!extraTexts &&
              curriculumBoundaryFeatureEnabled &&
              Object.keys(references).length <= 0 && (
                <div className="alert alert-light p-2 mt-2 border-none">
                  <FontAwesomeIcon className="me-2" icon={faInfoCircle} />
                  <small>{extraTexts}</small>
                </div>
              )}

            {/*References*/}
            {Object.keys(references).length > 0 &&
              curriculumBoundaryFeatureEnabled && (
                <CurriculumBoundaryReferences
                  extraTexts={extraTexts}
                  references={references}
                />
              )}
          </div>

          <div className="d-flex flex-wrap justify-content-between align-items-center mt-5 px-3 py-3 border-top">
            <div className="mb-2">
              <NurtureAIFeedback aiRequestId={aiRequestId} />
            </div>
            <div>
              <Button color="white" className="me-2" onClick={tryAgain}>
                Try again
              </Button>
              <Button onClick={() => setShowConfirmModal(true)}>
                Use Suggestion
              </Button>
            </div>
          </div>

          <ConfirmModal
            show={showConfirmModal}
            onConfirm={handleSuggest}
            title="Overwrite?"
            text="This will overwrite your current changes do you wish to proceed"
            toggle={() => setShowConfirmModal(!showConfirmModal)}
          />
        </div>
      )}
    </div>
  );
};

NurtureAIAssignmentGenerator.defaultProps = {
  onSuggest: null,
  extras: {},
};

NurtureAIAssignmentGenerator.propTypes = {
  onSuggest: PropTypes.func,
  extras: PropTypes.shape({}),
};

export default NurtureAIAssignmentGenerator;
