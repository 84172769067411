import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Button} from "core/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './nurture-ai.scss'
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

const NurtureAI = ({ className, buttonText, allowClose, display, open, children, done, subtitle, onBegin }) => {
  const [showChildren, setShowChildren] = useState(open)

  const toggleModal = () => setShowChildren(!showChildren);

  const begin = () => {
    if (typeof onBegin === 'function') onBegin(); // Notify consumer that AI generation is about to start
    setShowChildren(true);
  };

  useEffect(() => {
    if (done) setShowChildren(false);
  }, [done]);

  return (
    <div className={`nurture-ai ${className} py-2 p-3`}>
      <div className="nurture-ai-header d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center border-none">
        <div className="text-white">
          <div className="font-size-17 mb-1">Nurture Assistant ✨ </div>
          <div className="font-size-14">{ subtitle } </div>
        </div>
        <div className="ms-0 ms-md-3 mt-2 mt-md-0">
          {
            (!showChildren || display === 'modal') &&
            <Button color="light" onClick={begin}>{buttonText}</Button>
          }
          {
            showChildren && display === 'expand' && allowClose &&
            <Button color="light" className="btn-circle text-secondary" onClick={() => setShowChildren(false)}>
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          }
        </div>
      </div>

      {
        showChildren && display === 'expand' &&
        <div className="nurture-ai-body py-4 px-1 px-sm-3">
          { children }
        </div>
      }

      {
        display === 'modal' &&
        <Modal size="lg" fullscreen="sm" isOpen={showChildren} toggle={toggleModal} zIndex={102}>
          <ModalHeader toggle={toggleModal}>
            <div className="nurture-ai-header">
              <div className="font-size-17">Nurture Assistant ✨</div>
            </div>
          </ModalHeader>
          <ModalBody className="px-0 pb-0">
            { children }
          </ModalBody>
        </Modal>
      }
    </div>
  );
};

NurtureAI.defaultProps = {
  className: 'my-3',
  display: 'expand',
  subtitle: 'comment',
  done: false, // The consumer of this component informs it is done, so hide modal or expanded
  open: false,
  allowClose: true
};

NurtureAI.propTypes = {
  className: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  display: PropTypes.oneOf(['expand', 'modal']),
  done: PropTypes.bool,
  subtitle: PropTypes.string,
  open: PropTypes.bool,
  allowClose: PropTypes.bool,
};

export default NurtureAI;
