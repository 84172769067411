import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Button,
  Card,
  CardWithAccordion,
  Emoji,
} from 'core/components';
import { dateUtils, isEmpty, isPresent, statusDisplay } from 'core/utils';
import Form from './Form';
import FeedBack from '../assignments/feedback';
import StudentReflection from './studentReflection';
import './userAssignment.scss';
import TheAssignment from "../assignments/theAssignment/TheAssignment";
import AssignmentCardHeader from "../assignments/AssignmentCardHeader";
import {useSchoolConfig} from "../../core/hooks";
import InfoGreenIcon from "assets/images/general_icons/info_green.svg";
import QuotesIcon from "assets/images/general_icons/quotes.svg";
import Attachments from "../attachments/Attachments";
import OneNote from "../assignments/OneNote";
import {faEdit} from "@fortawesome/free-regular-svg-icons";
import RubricTable from "../assignments/feedback/RubricTable";

const UserAssignment = ({ assignment }) => {
  const [studentAssignment, setStudentAssignment] = useState(assignment);

  const {
    due_at,
    feedback_published_at,
    grade_display_to_students,
    graded,
    id,
    max_marks,
    submission_type,
    name,
    assignment_objectives,
    status,
    student_reflection,
    updated_at,
    user_assignment,
    user,
  } = studentAssignment;

  const statusArray = statusDisplay(status);
  const {
    stats,
    status: submissionStatus,
    student_submission: studentSubmission,
    submitted_at: submittedAt,
    teacher_feedback,
  } = user_assignment;

  const isOverdue = stats?.overdue;
  const notes = studentSubmission.notes;
  const submissionOnenote = user_assignment.onenote_url;
  const [submitted, setSubmitted] = useState(!!submittedAt);
  const [submissionAttachments, setSubmissionAttachments] = useState([]);

  const { learningObjectiveLabel } = useSchoolConfig();

  const openReviewedCard = submissionStatus.toLowerCase() === 'reviewed' && !!assignment?.feedback_published_at

  useEffect(() => {
    setSubmissionAttachments(user_assignment.stats.attachments);
  }, [user_assignment.stats.attachments]);
  const handleOnDelete = deletedAttachment => {
    const updatedAttachments = submissionAttachments.filter(attachment => attachment.id !== deletedAttachment.id);

    setSubmissionAttachments(updatedAttachments);
  };

  let pastDue = null;

  if (!isOverdue) {
    pastDue = 1;
  } else if (isEmpty(feedback_published_at)) {
    pastDue = 2;
  }

  const handleEditButtonClick = () => setSubmitted(false);
  const handleCancelButtonClick = () => setSubmitted(true);

  const handleAssignmentSubmitSuccess = userAssignment => {
    setStudentAssignment(current => ({
      ...current,
      user_assignment: {
        ...current.user_assignment,
        ...userAssignment,
      }
    }));

    setSubmitted(true);
  };

  // let formHeading = 'Submit Assignment';
  // let formSubHeading = 'Upload your assignment and leave any notes to the teacher.';

  // if (submitted) {
  //   formHeading = 'My Submission';
  //   formSubHeading = 'You can view your submission here. You can edit it anytime before the due date or before your work has been reviewed.';
  // }

  const hideReflection = isEmpty(feedback_published_at) || (!graded && student_reflection === 'disabled');

  return <>
    {/* Header */}
    <Card className='px-3 py-0'>
      <div className='d-md-flex justify-content-md-between pt-1 pb-2'>
        <div className='d-flex flex-column'>
          <div className='pb-2 section-header-title-large'>{name}</div>
          <div className='custom-badge d-none d-md-block'>
            <span className={`${statusArray[0]} rounded py-1 px-2 mid-big text-uppercase`}> {statusArray[1]}</span>
          </div>
        </div>

        <div className='d-md-flex flex-column text-md-center'>
          <small className="text-muted me-1 me-md-0">Due on:</small>
          <span className='pb-0 mb-0 section-header-title-large fs-5 d-none d-md-block'>{dateUtils.format({ date: due_at, format: 'LL' })}</span>
          <small className="text-muted m-0 d-md-none me-1">{ dateUtils.format({ date: due_at, format: 'LL' }) }</small>
          {pastDue === 1 && (
            <small className='text-muted fw-lighter'>({dateUtils.fromNow(due_at)})</small>
          )}
          {pastDue === 2 && (
            <span className='text text-danger pt-0 mt-0'>PAST DUE</span>
          )}
        </div>

        <div className='custom-badge d-md-none mt-2'>
          <span className={`${statusArray[0]} rounded py-1 px-2 mid-big text-uppercase`}> {statusArray[1]}</span>
        </div>
      </div>
    </Card>

    <TheAssignment openReviewedCard={openReviewedCard} assignment={studentAssignment} isTeacher={false} />

    {/* Learning Objectives */}
    <CardWithAccordion defaultState={openReviewedCard ? 'closed' : 'open'} id="LearningObjectives" title={learningObjectiveLabel}>
      <div className='card silent-card'>
        <div className="card-body">
          <div className="mb-3">
            <AssignmentCardHeader user={user} date={updated_at} />
          </div>

          <div className="my-4">
            <div className="d-flex align-items-center">
              <img className="me-2" src={InfoGreenIcon} />
              <span>Consider these when completing the assessment.</span>
            </div>
          </div>
          {
            assignment_objectives.map((objective, index) => {
              return (
                <div key={objective.id} className='d-flex align-items-center mt-2 mb-3 px-3 py-3 rounded' style={{backgroundColor: '#E4EBF6'}}>
                  <div className='me-3'>
                    <div className='avatar avatar-sm'>
                      <div className='avatar-title font-size-md rounded-circle bg-secondary'>{index + 1}</div>
                    </div>
                  </div>
                  <div className='col p-0 font-size-16'>{objective.name}</div>
                </div>
              )
            })
          }
        </div>
      </div>
    </CardWithAccordion>

    {/*Rubrics*/}
    {
      !!studentAssignment.rubrics &&
        <CardWithAccordion defaultState={openReviewedCard ? 'closed' : 'open'} title="Rubrics" id="RubricsTable">
          <div className="d-flex align-items-center mb-4">
            <img className="me-2" src={InfoGreenIcon} />
            <span>This assessment is graded using <strong>rubrics</strong> the maximum marks available is <strong>{studentAssignment.max_marks}</strong>.</span>
          </div>
          <RubricTable assignment={studentAssignment} hidePoints={true} />
        </CardWithAccordion>
    }

    {/* Student Submission */}
    <CardWithAccordion defaultState={openReviewedCard ? 'closed' : 'open'} id="StudentSubmission" title="My Submission">
      <div className='card silent-card'>
        <div className="card-body">
          {isOverdue && (
            <div className='alert alert-light mt-3'>
              <div className='row'>
                <div className='col-auto align-self-center'>
                  <div className='avatar'>
              <span className='avatar-title rounded-circle'>
                <FontAwesomeIcon icon={faTriangleExclamation} />
              </span>
                  </div>
                </div>

                <div className='col align-self-center'>
                  <span className='mt-4'>This assessment is past due. You can continue to submit your assignment but you might not receive any personal feedback from your teacher.</span>
                </div>
              </div>
            </div>
          )}

          {
            !submitted &&
              <Form
                assignmentId={id}
                submissionType={submission_type}
                onCancel={handleCancelButtonClick}
                onSuccess={handleAssignmentSubmitSuccess}
                userAssignment={user_assignment}
              />
          }

          {
            submitted &&
            <div className="d-flex flex-wrap justify-content-between">
              <div className="flex-grow-1">
                {/* Confidence level */}
                <div className="mb-4">
                  <div className="mb-3 font-size-14 font-weight-600 text-muted">CONFIDENCE LEVEL</div>
                  <div className="d-flex flex-wrap align-items-center">
                    <Emoji className="me-3" level={user_assignment?.student_submission?.confidence?.level} size="lg" />
                    <div className="font-size-16">{ user_assignment?.student_submission?.confidence?.text }</div>
                  </div>
                </div>

                {/* Student Notes */}
                <div className="mb-4">
                  <div className="mb-3 font-size-14 font-weight-600 text-muted">STUDENT COMMENTS</div>
                  <div className="mb-2"><img src={QuotesIcon} /></div>
                  <div className="font-size-16">{ notes }</div>
                </div>

                {/* Attachments */}
                <div>
                  <div className="mb-3 font-size-14 font-weight-600 text-muted">YOUR ATTACHMENTS</div>
                  {isPresent(submissionAttachments) && (
                    <div className='mt-2 px-custom'>
                      <Attachments attachments={submissionAttachments} onDelete={handleOnDelete} />
                    </div>
                  )}
                  {
                    submissionOnenote && submissionOnenote.trim() !== "" &&
                    <div className="">
                      <OneNote onenote_url={submissionOnenote} allowDelete={false} />
                    </div>
                  }
                </div>
              </div>
              {
                user_assignment.status !== 'reviewed' &&
                  <div>
                    <Button className="btn-sm" outline={true} onClick={handleEditButtonClick}>
                      <FontAwesomeIcon className="me-2" icon={faEdit} />
                      Edit
                    </Button>
                  </div>
              }
            </div>
          }
        </div>
      </div>
    </CardWithAccordion>

    {/* Feedback */}
    {(submissionStatus === 'reviewed') && (
      <CardWithAccordion defaultState={openReviewedCard ? 'open' : 'closed'} id="TeacherFeedback" title="Teacher Feedback">
        <div className='card silent-card'>
          <div className="card-body">
            {isPresent(feedback_published_at) && (
              <div className="mb-3">
                <AssignmentCardHeader user={user} date={assignment?.feedback_published_at}/>
              </div>
            )}

            <FeedBack assignment={studentAssignment}/>
          </div>
        </div>
      </CardWithAccordion>
    )}

    {(submissionStatus === 'reviewed' && !hideReflection &&
      <CardWithAccordion defaultState={openReviewedCard ? 'open' : 'closed'} id="StudentReflection" title={user_assignment.student_reflection_completed ? 'Your Grade' : 'Your Reflection'}>
        <div className='card silent-card'>
          <div className="card-body">
            <StudentReflection
              assignmentId={id}
              feedbackPublished={isPresent(feedback_published_at)}
              graded={graded}
              gradeType={grade_display_to_students}
              maxMarks={max_marks}
              score={teacher_feedback.grade}
              studentReflection={student_reflection}
              userAssignment={user_assignment}
              onSuccess={handleAssignmentSubmitSuccess}
              hideReflection={hideReflection}
            />
          </div>
        </div>
      </CardWithAccordion>
      )}
  </>
};

UserAssignment.propTypes = {
  assignment: PropTypes.shape({
    updated_at: PropTypes.any,
    description: PropTypes.string,
    due_at: PropTypes.any,
    name: PropTypes.string,
    user_assignment: PropTypes.shape({}),
    user: PropTypes.shape({}),
    status: PropTypes.string,
  }).isRequired,
};

export default UserAssignment;
