import { FormGroup, FormText} from "reactstrap";
import { RadioButton, Switch, TextInput } from "core/form/fields";
import { Card, EditableTable, NurtureAI } from "core/components";
import React from "react";
import PropTypes from "prop-types";
import SubmissionType from "./SubmissionType";
import { useSchoolConfig } from "core/hooks";
import { NurtureAIRubricsGenerator } from "core/components/nurtureAI";

const AssignmentGradingForm = ({ formConfig, assignment }) => {
  const { nurtureAIEnabled, rubricsEnabled } = useSchoolConfig();

  const gradingHeaderText = '2. Grading'
  const assignmentGrades = [
    { label: 'Marks', value: 'marks' },
    { label: 'Percentage', value: 'percentage' },
  ];

  if (rubricsEnabled) assignmentGrades.push({ label: 'Rubrics', value: 'rubrics' })

  const { formState: { errors }, register, watch, getValues, setValue } =  formConfig;
  const watchGraded = watch('graded');
  const watchGradeDisplayToStudent = watch('grade_display_to_students');
  const watchLearningObjectives = watch('assignment_objectives');
  const watchRubrics = watch('rubrics');

  const { submission_type } = assignment || {};

  const handleRubricsChange = (rubrics) => {
    setValue('rubrics', rubrics);
  }

  const handleWheel = (e) => {
    e.currentTarget.blur();
  };

  /**
   * This code defines a function called handleKeyDown that takes an
   * event object as a parameter. It checks if the key code of the
   * event is either 38 or 40, which correspond to the up and
   * down arrow keys respectively. If the key code matches either
   * of these values, the function prevents the default behavior
   * of the event.
   *
   * @param {type} paramName - description of parameter
   * @return {type} description of return value
   */
  const handleKeyDown = (e) => {
    if (e.which === 38 || e.which === 40) {
      e.preventDefault();
    }
  }

  return (
    <Card className="mb-5 px-1 px-md-3 p-3" headerText={gradingHeaderText} headerClass="big-text text-nurture-purple" withHeader>
      <FormGroup className="ps-3">
        <Switch label="Grade this assessment" {...register('graded')} />
      </FormGroup>
      {watchGraded && (
        <>
          <FormGroup>
            <div className="d-flex flex-wrap mt-3">
              {
                assignmentGrades.map((item) => (
                  <div className="me-4">
                    <RadioButton
                      error={!!errors.grade_display_to_students}
                      label={item.label}
                      value={item.value}
                      {...register('grade_display_to_students')}
                    />
                  </div>
                ))
              }
            </div>
            {errors.grade_display_to_students && (
              <p className="mt-1">
                <FormText color="danger">
                  {errors.grade_display_to_students.message}
                </FormText>
              </p>
            )}
          </FormGroup>

          {
            watchGradeDisplayToStudent !== 'rubrics' &&
              <FormGroup>
                <TextInput
                  type="number"
                  label="Maximum Marks"
                  placeholder="Maximum Marks"
                  error={!!errors.max_marks}
                  onWheel={handleWheel}
                  onKeyDown={handleKeyDown}
                  {...register('max_marks')}
                />
                {errors.max_marks && (
                  <p className="mt-1">
                    <FormText color="danger">
                      {errors.max_marks.message}
                    </FormText>
                  </p>
                )}
              </FormGroup>
          }

          {
            watchGradeDisplayToStudent === 'rubrics' && rubricsEnabled &&
              <>
                {
                  nurtureAIEnabled &&
                    <NurtureAI done={watchRubrics} buttonText="Generate Rubrics" className="mb-4 mt-2" subtitle="Generate a rubric for your assessment with Nurture Assistant">
                      <NurtureAIRubricsGenerator
                        assignment={assignment}
                        learningObjectives={watchLearningObjectives}
                        onSuggest={(rubrics) => handleRubricsChange(rubrics)} />
                    </NurtureAI>
                }

                <EditableTable onUpdated={(rubrics) => handleRubricsChange(rubrics)} learningObjectives={watchLearningObjectives} defaultData={watchRubrics || []} />
              </>
          }
        </>
      )}

      {/*<hr />*/}

      {/*Submission Type*/}
      {/*<SubmissionType register={register} submissionType={submission_type}/>*/}
    </Card>
  );
};

AssignmentGradingForm.propTypes = {
  formConfig: PropTypes.any.isRequired,
  assignment: PropTypes.shape({}).isRequired
};

export default AssignmentGradingForm;
