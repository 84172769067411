import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './header.scss';
import { useSchoolConfig } from "../core/hooks";
import {useNavigate} from "react-router-dom";
import HeaderNew from "./HeaderNew";

const Header = ({ name, role, activeTab, onTabChanged }) => {
  const displayName = name ? `, ${name}!` : '!';
  const navigateTo = useNavigate();


  const newAssignment = () => navigateTo('/assignments/new');

  return (
    <div className="container mb-5">
      <div className="pb-3 mb-4">
        <div className='fs-3 small-screen-text d-flex align-items-center'>
          <div className='me-2'>👋 Hello{displayName}</div>
        </div>
      </div>

      <div className=''>
        <HeaderNew activeTab={activeTab} onTabChange={onTabChanged} onNewAssignment={newAssignment} />
      </div>

      {/*{role === 'teacher' && (*/}
      {/*  <div className="col-12 col-md-auto justify-content-md-end d-flex flex-wrap">*/}
      {/*    <ul className="nav pe-2 ps-4 pe-sm-0 flex-wrap">*/}
      {/*      <li*/}
      {/*        className={`nav-item cursor-pointer me-4 ${activeTab === 'assignments' ? 'active-tab' : ''}`}*/}
      {/*        onClick={() => onTabChanged('assignments')}>*/}
      {/*        <div className="nav-link px-0 text-muted">My Assessments</div>*/}
      {/*      </li>*/}
      {/*      <li*/}
      {/*        className={`nav-item cursor-pointer me-4 ${activeTab === 'assignments_templates' ? 'active-tab' : ''}`}*/}
      {/*        onClick={() => onTabChanged('assignments_templates')}>*/}
      {/*        <div className="nav-link px-0 text-muted">*/}
      {/*          Assessment Templates*/}
      {/*        </div>*/}
      {/*      </li>*/}
      {/*      {*/}
      {/*        msTeamsPermissionMissing &&*/}
      {/*        <li className={`nav-item cursor-pointer me-4 ${activeTab === 'manage_students' ? 'active-tab' : ''}`}*/}
      {/*            onClick={() => onTabChanged('manage_students')}>*/}
      {/*          <div className="nav-link px-0 text-muted">*/}
      {/*            Manage Students*/}
      {/*          </div>*/}
      {/*        </li>*/}
      {/*      }*/}

      {/*      <div className={`dropdown nav-item ${['classroom_settings', 'help_resources'].includes(activeTab) ? 'active-tab' : ''}`}>*/}
      {/*        <button className="btn btn-white no-hover" type="button" id="settingsDropdown"*/}
      {/*                data-bs-toggle="dropdown" aria-expanded="false" onClick={() => setSettingsOpened(!settingsOpened)}>*/}
      {/*          <img src={SettingsIcon} width={26} height={26} className="me-2" />*/}
      {/*          <img src={settingsOpened ? AngleUp : AngleDown} width={22} height={22} />*/}
      {/*        </button>*/}
      {/*        <ul className="dropdown-menu" aria-labelledby="settingsDropdown">*/}
      {/*          <li>*/}
      {/*            <div className={`dropdown-item cursor-pointer ${activeTab === 'classroom_settings' ? 'active' : ''}`} onClick={() => {onTabChanged('classroom_settings'); setSettingsOpened(false)}}>*/}
      {/*              <img src={UserGroupsIcon} className="me-2" /> Classroom Settings*/}
      {/*            </div>*/}
      {/*          </li>*/}
      {/*          <li>*/}
      {/*            <div className={`dropdown-item cursor-pointer ${activeTab === 'help_resources' ? 'active' : ''}`} onClick={() => {onTabChanged('help_resources'); setSettingsOpened(false)}}>*/}
      {/*              <img src={HelpResourcesIcon} className="me-2" /> Nurture Resources*/}
      {/*            </div>*/}
      {/*          </li>*/}
      {/*        </ul>*/}
      {/*      </div>*/}
      {/*    </ul>*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  );
};

Header.defaultProps = {
  name: undefined,
  role: undefined,
  activeTab: '',
  onTabChanged: undefined,
};

Header.propTypes = {
  name: PropTypes.string,
  role: PropTypes.string,
  activeTab: PropTypes.string,
  onTabChanged: PropTypes.func,
};

export default Header;
