import { dateUtils } from "core/utils";
import { useAssignmentInfo } from "core/hooks";
import SeparatorIcon from "assets/images/general_icons/separator.svg";
import PropTypes from "prop-types";

const AssignmentDatesInfo = ({ assignment, color }) => {
  const {
    publishedUserAssignments,
    totalUserAssignments
  } = useAssignmentInfo(assignment);

  const scheduledAt = assignment?.publish_at;
  const user = JSON.parse(sessionStorage.getItem('user'));
  const isTeacher = user?.role === 'teacher';

  const Separator = () => <img className="mx-2" src={SeparatorIcon} alt="Separator" />

  const colorStyle = {color: color};

  return (
    <div className="d-flex flex-wrap">
      {
        scheduledAt && isTeacher &&
          <>
            <div className='small-texts' style={colorStyle}>Scheduled for: {dateUtils.format({
              date: assignment?.publish_at,
              format: 'MMMM D, YYYY'
            })}
            </div>
          </>
      }
      {
        !scheduledAt &&
          <>
            <div className='small-texts' style={colorStyle}>Created: {dateUtils.format({
              date: assignment?.created_at,
              format: 'MMMM D, YYYY'
            })}</div>
          </>
      }

      <Separator />
      <div className='small-texts' style={colorStyle}>Due: {dateUtils.format({
        date: assignment?.due_at,
        format: 'MMMM D, YYYY'
      })}</div>

      {
        !scheduledAt && isTeacher &&
          <>
            <Separator />
            <div className='small-texts' style={colorStyle}>Feedback Published
              to {publishedUserAssignments}/{totalUserAssignments} students
            </div>
          </>
      }
    </div>
  );
};


AssignmentDatesInfo.propTypes = {
  assignment: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired
};

export default AssignmentDatesInfo;