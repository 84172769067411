import {
  Button,
  Header,
  MarkdownEditorField,
  NurtureAI,
  NurtureAICommentSuggestion,
  RubricGradingTable,
  TextHeader
} from "core/components";
import Attachment from "modules/attachments/Attachment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCancel, faCheck, faPaperclip} from "@fortawesome/free-solid-svg-icons";
import filestackPicker from "core/utils/filestack";
import {useDispatch, useSelector} from "react-redux";
import * as attachmentsSelector from "modules/attachments/selectors";
import React, {useEffect, useState} from "react";
import {isPresent, pluralize} from "core/utils";
import {assignmentShowSuccess, submissionUpdate} from "../action";
import {attachmentCreate} from "modules/attachments/actions";
import PropTypes from "prop-types";
import {useSnackbar} from 'notistack';
import * as api from "../api";
import {Switch} from "core/form/fields";
import {useSchoolConfig} from "core/hooks";
import NurtureAIAssistantCommentSuggestion
  from "../../../core/components/nurtureAI/NurtureAIAssistantCommentSuggestion";

const SubmissionReviewForm = ({
  assignment,
  errors,
  handleSubmit,
  onSubmissionChange,
  register,
  setValue,
  submission,
  externalTeacherAction,
  onAssignmentObjectivesChanged,
  watch,
  onCancel,
}) => {
  const [reviewedSavedText, setReviewedSavedText] = useState('');
  const [submitButtonText, setSubmitButtonText] = useState('Mark as reviewed');
  const [submitButtonTextDisabled, setSubmitButtonTextDisabled] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [assignmentObjectives, setAssignmentObjectives] = useState([]);
  const [aiSuggestedAssignmentObjectives, setAiSuggestedAssignmentObjectives] = useState([]);
  const [rubricGrades, setRubricGrades] = useState([]);
  const [aiRubricGrades, setAiRubricGrades] = useState([]);
  const [personalFeedback, setPersonalFeedback] = useState(submission.teacher_feedback?.personal_feedback || '');
  const [personalFeedbackChanged, setPersonalFeedbackChanged] = useState(false);
  const [grade, setGrade] = useState(submission.teacher_feedback?.grade || null);
  const [gradeChanged, setGradeChanged] = useState(false);
  const [teacherAction, setTeacherAction] = useState(null);
  const [timerID, setTimerID] = useState();
  const [rubricsTimerID, setRubricsTimerID] = useState();
  const [gradeTimerID, setGradeTimerID] = useState();
  const [feedbackTimerID, setFeedbackTimerID] = useState();

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { learningObjectiveLabel, rubricsEnabled, nurtureAssistantEnabled, nurtureAIEnabled } = useSchoolConfig();
  const filestackCredentials = useSelector(attachmentsSelector.filestackCredentials);
  const NURTURE_AI_HINTS = ['Well done', 'Need Improvement', 'Encourage'];

  useEffect(() => {
    if (isPresent(assignment)) setValue('graded', assignment.graded)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignment]);

  useEffect(() => {
    setReviewedSavedText('');
    setAiSuggestedAssignmentObjectives(() => [])
    setTeacherAction(null);
    if (isPresent(submission)) {
      setAttachments(submission?.teacher_feedback?.personal_feedback_attachments || [])

      setValue('personal_feedback', submission.teacher_feedback?.personal_feedback)
      setValue('grade', submission.teacher_feedback?.grade || '')
      setValue(
        'assignment_objective_ids',
        submission?.teacher_feedback?.feedback_objectives?.map(item => item.id.toString()) || []
      )

      setAssignmentObjectives(prevState => {
        const result = submission?.teacher_feedback?.feedback_objectives?.map(item => item.id.toString()) || [];
        onAssignmentObjectivesChanged(result);
        return result;
      });
      setPersonalFeedback(submission?.teacher_feedback?.personal_feedback || '')
      setRubricGrades(submission.rubric_grades)
      setGrade(submission.teacher_feedback?.grade || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submission]);

  const hasFeedback = (objective) => {
    if (aiSuggestedAssignmentObjectives.length) return aiSuggestedAssignmentObjectives.includes(objective.id.toString());

    return assignmentObjectives.includes(objective.id.toString());
  }

  const handleGradeRubrics = (rubricsGrades, ai = false) => {
    if (ai) return setRubricGrades(rubricsGrades);

    clearTimeout(rubricsTimerID);
    setRubricGrades(rubricsGrades);

    const timerId = setTimeout(() => {
      setTeacherAction(() => { return { type: 'rubrics', value: rubricsGrades } });
    }, 5000);
    setRubricsTimerID(timerId)
  }

  const handleObjectiveChange = (e, id) => {
    clearTimeout(timerID);
    let finalObjectives;

    if (e.target.checked) {
      setAssignmentObjectives(prevState => {
        const result = [...prevState, id.toString()];
        onAssignmentObjectivesChanged(result);
        finalObjectives = result;
        return result;
      });
      setAiSuggestedAssignmentObjectives(prevState => [...prevState, id.toString()]);

    } else {
      setAssignmentObjectives(prevState => {
        const result = prevState.filter(item => item !== id.toString());
        onAssignmentObjectivesChanged(result);
        finalObjectives = result;
        return result
      });
      setAiSuggestedAssignmentObjectives(prevState => prevState.filter(item => item !== id.toString()));
    }

    const timerId = setTimeout(() => {
      setTeacherAction(() => { return { type: 'learning_objectives', value: finalObjectives } });
    }, 5000);
    setTimerID(timerId)
  }

  const onUploaderButtonClick = () => {
    const filePicker = filestackPicker(filestackCredentials, handleUploadSuccess);
    filePicker.open()
  }

  const handleUploadSuccess = async (file) => {
    try {
      const payload = { user_assignment_id: submission.id, ...file }
      const attachment = await dispatch(attachmentCreate(payload))

      setAttachments((currentAttachments) => [...currentAttachments, attachment])
      enqueueSnackbar('Attachment was uploaded', { variant: 'success' })
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' })
    }
  }

  const handlePersonalFeedbackBlur = () => {
    if (personalFeedbackChanged && personalFeedback) {
      clearTimeout(feedbackTimerID);

      const action = { type: 'personal_comment', value: personalFeedback };

      const timerId = setTimeout(() => setTeacherAction(() => action), 5000);
      setFeedbackTimerID(timerId);
    }

    setPersonalFeedbackChanged(false);
  }

  const handlePersonalFeedbackChange = (value) => {
    setPersonalFeedbackChanged(true);
    setPersonalFeedback(value);
    setValue('personal_feedback', value);
  }

  const handleGradeInputBlur = () => {
    if (gradeChanged && grade) {
      clearTimeout(gradeTimerID);
      const action = { type: 'grade', value: grade };

      const timerId = setTimeout(() => setTeacherAction(() => action), 5000);
      setGradeTimerID(timerId);
    }

    setGradeChanged(false);
  }

  const handleGradeChanged = (e) => {
    setGradeChanged(true)
    const value = e.target.value
    setGrade(value)
    setValue('grade', value)
  }

  const handleGradeSuggestion = (value) => {
    setValue('grade', value);
    setGrade(value);
  }

  const handleLearningObjectivesSuggestion = (objectives) => {
    setAiSuggestedAssignmentObjectives(() => objectives);
    setAssignmentObjectives(() => objectives);
  }

  const handleRubricGrade = (rubricsGrades) => {
    setAiRubricGrades((prev) => rubricsGrades)
  }

  const handleAttachmentDelete = deletedAttachment => {
    const updatedAttachments = attachments?.filter((attachment) => attachment.id !== deletedAttachment.id)
    setAttachments(updatedAttachments)
    enqueueSnackbar('Attachment was deleted', { variant: 'success' })
  }

  const onSubmit = async data => {
    try {
      setSubmitButtonText('Loading...');
      setSubmitButtonTextDisabled(true);

      data = { ...data, assignment_objective_ids: assignmentObjectives }
      if (assignment.grade_display_to_students === 'rubrics') data['rubric_grades_attributes'] = rubricGrades

      const response = await submissionUpdate(assignment.id, submission.id, data);

      /**
       * When the teacher reviews the student work, there are some changes that occur on the
       * assignment on the backend, so update the assessment in the store to match the
       * assignment state in the backend
       */
      const { data: updatedAssignment } = await api.getAssignment(assignment.id);

      onSubmissionChange(response);
      dispatch(assignmentShowSuccess(updatedAssignment));
      setReviewedSavedText('Saved');
      enqueueSnackbar('Submission has been marked as reviewed.', { variant: 'success' });
    } catch (e) {
      const msg = e?.message || 'Could not save review. Please try again later'
      enqueueSnackbar(msg, { variant: 'error' });
    } finally {
      setSubmitButtonText('Mark as reviewed');
      setSubmitButtonTextDisabled(false);
    }
  };

  const handleWheel = (e) => {
    e.currentTarget.blur();
  };

  /**
   * This code defines a function called handleKeyDown that takes an
   * event object as a parameter. It checks if the key code of the
   * event is either 38 or 40, which correspond to the up and
   * down arrow keys respectively. If the key code matches either
   * of these values, the function prevents the default behavior
   * of the event.
   *
   * @param {type} paramName - description of parameter
   * @return {type} description of return value
   */
  const handleKeyDown = (e) => {
    if (e.which === 38 || e.which === 40) {
      e.preventDefault();
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/*<Header title="Review Submission" />*/}
      <div className="card py-3">
        <div className="card-header d-flex justify-content-between align-items-center flex-wrap">
          <TextHeader className="card-header-title">Review Submission</TextHeader>

          {
            isPresent(assignment?.feedback_published_at) &&
            <Button className="btn-sm" outline onClick={onCancel}>
              <FontAwesomeIcon icon={faCancel} className="me-1" />
              Cancel
            </Button>
          }
        </div>
        <div className="card-body p-0 border rounded">
          <div className="row mx-0">
            <div className={`${nurtureAssistantEnabled ? 'col-lg-7 col-md-6 order-1 order-md-0 border-end' : 'col-12'}  bg-nurture-light-grey p-3`}>
              {/* Assignment Objectives that needs feedback */}
              {
                assignment.grade_display_to_students !== 'rubrics' &&
                <>
                  <div className="row">
                    <div className="col">
                      <p className="mb-0">{pluralize(learningObjectiveLabel)} student needs feedback on</p>
                      <div className="small text-muted mb-4">You can check multiple {learningObjectiveLabel.toLowerCase()} or none.</div>
                      {
                        assignment?.assignment_objectives.map((objective, index) => (
                          <div className="assignment_objective_switch_wrapper" key={index}>
                            <div className="form-check form-switch ps-3 mb-3">
                              <Switch
                                label={objective.name}
                                checked={hasFeedback(objective)}
                                value={objective.id}
                                id={`LearningObjectiveSwitch${objective.id}`}
                                onClick={(e) => handleObjectiveChange(e, objective.id)}
                                {...register(`assignment_objective_ids[${index}]`)} />
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                  <hr className="my-4" />
                </>
              }

              <div className="row">
                <div className="col">
                  <TextHeader className="mb-0">
                    {assignment.grade_display_to_students === 'rubrics' ? 'Grade Rubrics' : 'Personal Feedback'}
                  </TextHeader>
                  <small className="form-text text-muted mt-2 mb-3">
                    You can leave any personal feedback just for this student.
                  </small>
                  <div className="mt-3">
                    {/* Max mark field if assignment is not to be graded with rubrics */}
                    {
                      !!assignment?.graded && assignment.grade_display_to_students !== 'rubrics' &&
                      <div className="mb-3">
                        <label className="form-label">Marks <span className="text-danger">*</span></label>
                        <div className="input-group input-group-merge">
                          <input type="number"
                                 className={`student-grade form-control form-control-appended border-end-0 ${!!errors.grade ? 'error' : ''}`}
                                 placeholder="Marks"
                                 step='.01'
                                 value={grade}
                                 min={0}
                                 max={assignment?.max_marks}
                                 onWheel={handleWheel}
                                 onKeyDown={handleKeyDown}
                                 onBlur={handleGradeInputBlur}
                                 onChange={handleGradeChanged}
                          />
                          <span className="input-group-text text-muted border-start-0">/{assignment?.max_marks}</span>
                        </div>
                        {errors.grade && <><small className="mt-1 text-danger form-text">{errors.grade.message}</small> <br /></>}
                        <small className="text-muted form-text mt-2">Select marks given for this submission.</small>
                      </div>
                    }

                    {/* Rubrics grading if assignment is to be graded with rubrics */}
                    {
                      !!assignment?.graded && assignment.grade_display_to_students === 'rubrics' && rubricsEnabled &&
                      <div className="mb-4">
                        <RubricGradingTable
                          assignment={assignment}
                          submission={submission}
                          setValue={setValue}
                          aiRubricGrades={aiRubricGrades}
                          onGraded={handleGradeRubrics} />
                      </div>
                    }

                    <div>
                      <div className="mb-3">
                        {assignment?.graded && <label className="form-label">Personal Comments</label>}
                        <div>
                          <MarkdownEditorField
                            className='markdown'
                            initialValue={personalFeedback}
                            error={errors.personal_feedback}
                            onChange={handlePersonalFeedbackChange}
                            onBlur={handlePersonalFeedbackBlur}
                          />
                        </div>

                        {errors.personal_feedback && <><small className="mt-1 text-danger form-text">{errors.personal_feedback.message}</small> <br /></>}
                        <small className="text-muted form-text mt-2">
                          You can leave any personal feedback here which are only visible to this student.
                          This will become visible to the student when all feedback is sent.
                        </small>
                        {
                          nurtureAIEnabled && !nurtureAssistantEnabled &&
                          <NurtureAI done={submission} buttonText="Suggest Comment" subtitle="Get help and ideas for your comment">
                            <NurtureAICommentSuggestion
                              extras={{user_assignment_id: submission.id, watch, rubricGrades, assignmentObjectives}}
                              hints={NURTURE_AI_HINTS}
                              onSuggest={handlePersonalFeedbackChange}
                            />
                          </NurtureAI>
                        }
                      </div>
                      <div className="attachment-list edit mb-5">
                        {
                          attachments.map((attachment, index) => (
                            <Attachment attachment={attachment} allowDelete key={index} onDelete={handleAttachmentDelete} />
                          ))
                        }
                      </div>
                      <Button type="button" color="nurture-purple" outline className="btn-sm" onClick={onUploaderButtonClick}>
                        <FontAwesomeIcon icon={faPaperclip} className="me-1" />&nbsp;
                        Add Attachments
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {
              nurtureAssistantEnabled &&
                <div className="col-lg-5 col-md-6 order-0 order-md-1">
                  {
                    <NurtureAIAssistantCommentSuggestion
                      submission={submission}
                      assignment={assignment}
                      teacherAction={teacherAction}
                      externalTeacherAction={externalTeacherAction}
                      onGradeSuggestion={handleGradeSuggestion}
                      onPersonalCommentSuggestion={handlePersonalFeedbackChange}
                      onLearningObjectivesSuggestion={handleLearningObjectivesSuggestion}
                      onRubricsGraded={handleRubricGrade}
                    />
                  }

                  {/*{*/}
                  {/*  !isPresent(submission.conversations) &&*/}
                  {/*    <NurtureAI open={true} buttonText="Suggest Comment" subtitle="Get help and ideas for your comment">*/}
                  {/*      <NurtureAICommentSuggestion*/}
                  {/*        extras={{user_assignment_id: submission.id, watch, rubricGrades, assignmentObjectives}}*/}
                  {/*        hints={NURTURE_AI_HINTS}*/}
                  {/*        onSuggest={handlePersonalFeedbackChange}*/}
                  {/*      />*/}
                  {/*    </NurtureAI>*/}
                  {/*}*/}
                </div>
            }
          </div>
        </div>
        <div className="card-footer">
          <div className="d-grid">
            <Button
              disabled={submitButtonTextDisabled}
              className="btn-block"
              loading={submitButtonTextDisabled}
              type="submit">{submitButtonText}</Button>
          </div>
          {
            reviewedSavedText &&
            <div className="text-center text-success mt-3">
              <FontAwesomeIcon icon={faCheck} className="me-2" />
              Saved
            </div>
          }
          {
            submission?.status === 'reviewed' &&
            <div className="text-center text-muted mt-3">
              <FontAwesomeIcon icon={faCheck} className="me-2" />
              Reviewed
            </div>
          }
        </div>
      </div>
    </form>
  );
}

SubmissionReviewForm.propTypes = {
  onCancel: () => {}
}

SubmissionReviewForm.propTypes = {
  assignment: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmissionChange: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  submission: PropTypes.object.isRequired,
  watch: PropTypes.object.isRequired,
  externalTeacherAction: PropTypes.object.isRequired,
  onAssignmentObjectivesChanged: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

export default SubmissionReviewForm;