import { envVariables, teamsEnv } from "core/utils";
import metricService from "../../../lib/metricService";

export const streamResponse = (query, onMessage, onError = null) => {
  const { tenantId, channelId } = teamsEnv;

  const url = `${envVariables.apiBase}/api/v1/ms_teams/${tenantId}/channels/${channelId}/ai/stream_response?${query}`
  const eventSource = new EventSource(url);

  eventSource.onmessage = (event) => {
    onMessage(event);
  };

  eventSource.onerror = (error) => {
    console.log('Error occurred', error);
    if (onError) onError();
    eventSource.close();
  };

  return () => {
    eventSource.close();
  };
};

export const trackDuration = (scope) => {
  const sessionStartTime = window.sessionStorage.getItem('startTimeInUnix');
  if (sessionStartTime) {
    const end = currentTimeInUnix();

    const durationInSeconds = end - parseInt(sessionStartTime)
    metricService.track({ event: 'ai_latency', properties: { duration: durationInSeconds, scope: scope } });
    resetStartTime();
  }
}

export const setStartTime = () => {
  window.sessionStorage.setItem('startTimeInUnix', currentTimeInUnix().toString());
}

export const resetStartTime = () => {
  window.sessionStorage.removeItem('startTimeInUnix');
}

export const currentTimeInUnix = () => {
  return Math.floor(Date.now() / 1000);
}


