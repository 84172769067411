import { Avatar, Button, Header, Emoji } from "core/components";
import PropTypes from "prop-types";
import React, {Fragment, useEffect, useState} from "react";
import {learningObjectivesHelper, userFullName} from "core/utils";
import { submissionShow } from "../action";
import { useSnackbar } from "notistack";
import SubmissionModal from "./SubmissionModal";
import { ExportJsonCsv } from 'react-export-json-csv'
import orderBy from "lodash/orderBy";

const Submissions = ({ assignment }) => {
  const [showSubmissionModal, setShowSubmissionModal] = useState(false);
  const [submissionData, setSubmissionData] = useState({});
  const [submissions, setSubmissions] = useState([]);
  const [sortableColumns, setSortableColumns] = useState({});
  const [loading, setLoading] = useState(false);
  const [currentSubmission, setCurrentSubmission] = useState(null);
  const [csvItems, setCsvItems] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const { objectivesThatDoNotNeedFeedback, objectivesThatNeedsFeedback, objectiveNeedFeedback } = learningObjectivesHelper;

  const csvHeaders = [
    { key: 'first_name', name: 'First Name' },
    { key: 'last_name', name: 'Last Name' },
    { key: 'grade', name: 'Grade' },
    { key: 'max_marks', name: 'Max Marks' },
    { key: 'grade_as_percent', name: 'Grade as percentage' },
    { key: 'confidence', name: 'Confidence' },
    { key: 'status', name: 'Status' },
    { key: 'loAchieved', name: 'Learning objectives achieved' },
    { key: 'loNotAchieved', name: 'Learning objectives not achieved' },
  ];

  const SubmissionBadge = ({ submission }) => {
    let result;
    if (!!submission.reflection_submitted_at) {
      result = <span className="badge bg-success">Feedback Reviewed</span>
    } else if (submission?.status.toLowerCase() === 'reviewed' && !!assignment?.feedback_published_at) {
      result = <span className="badge bg-success">Feedback Sent</span>
    } else if (submission?.status.toLowerCase() === 'reviewed') {
      result = <span className="badge bg-primary">Reviewed</span>
    } else if (submission?.status.toLowerCase() === 'pending' && submission?.stats.overdue) {
      result = <span className="badge bg-danger">Overdue</span>
    } else if (submission?.status.toLowerCase() === 'pending' && !submission?.stats.overdue) {
      result = <span className="badge bg-warning">Pending</span>
    } else {
      result = <span className="badge bg-warning">Pending Review</span>
    }

    return result
  }

  const submissionStatusText = (submission) => {
    let result;
    if (!!submission.reflection_submitted_at) {
      result = 'Feedback Reviewed'
    } else if (submission?.status.toLowerCase() === 'reviewed' && !!assignment?.feedback_published_at) {
      result = 'Feedback Sent'
    } else if (submission?.status.toLowerCase() === 'reviewed') {
      result = 'Reviewed'
    } else if (submission?.status.toLowerCase() === 'pending' && submission?.stats.overdue) {
      result = 'Overdue'
    } else if (submission?.status.toLowerCase() === 'pending' && !submission?.stats.overdue) {
      result = 'Pending'
    } else {
      result = 'Pending Review'
    }

    return result
  }

  const handleViewSubmission = async (submission) => {
    try {
      setLoading(true);
      setCurrentSubmission(submission);
      const submissionData = await submissionShow(assignment.id, submission.id)
      setSubmissionData(submissionData)
      setShowSubmissionModal(true)
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' })
    } finally {
      setLoading(false);
    }
  };

  const learningObjectiveClass = (submission, objective) => {
    if (submission?.status?.toLowerCase() !== 'reviewed') return ''

    const needsReview = objectiveNeedFeedback(submission, objective);

    return needsReview ? 'bg-nurture-orange' : 'bg-success';
  };

  const isCurrentSubmission = (submission) => submission.id === currentSubmission.id;
  const toggleSubmissionModal = () => setShowSubmissionModal(!showSubmissionModal);

  const cleanConfidence = (confidence) => {
    if (!confidence) return ''

    return confidence.replace(/confidence/gi, '').trim();
  };

  const addDefaults = (submissions) => {
    const clone = [...submissions];

    return clone.map((submission) => {
      return {
        ...submission,
        name: userFullName(submission?.user),
        confidence: !!submission?.student_submission?.confidence.text ? cleanConfidence(submission?.student_submission?.confidence.text) : '-',
        studentGrade: `${submission.grade || '_'}/${assignment.max_marks}`,
        submissionStatusText: submissionStatusText(submission),
        learningObjectives: objectivesThatNeedsFeedback(assignment?.assignment_objectives, submission).length,
      }
    });
  };

  const sortSubmissions = (arr, key, columns = null, direction = '') => {
    if (columns === null) columns = sortableColumns
    const sortableColumnsClone = {...columns};
    let sortingDirection = sortableColumnsClone[key]

    if (sortingDirection === '' || sortingDirection === 'desc') sortingDirection = 'asc'
    else sortingDirection = 'desc'

    if (['asc', 'desc'].includes(direction)) sortingDirection = direction

    for (let sortingKey in sortableColumnsClone) {
      sortableColumnsClone[sortingKey] = ''
    }

    sortableColumnsClone[key] = sortingDirection
    setSortableColumns(() => sortableColumnsClone)

    const sortedResults = orderBy(arr, [key], [sortingDirection])
    setSubmissions( () => sortedResults);

    const csvData = sortedResults.map((submission) => {
      return {
        first_name: submission?.user?.first_name || userFullName(submission?.user),
        last_name: submission?.user?.last_name || '',
        confidence: !!submission?.student_submission?.confidence.text ? cleanConfidence(submission?.student_submission?.confidence.text) : '_',
        grade: submission.grade || '_',
        max_marks: assignment.max_marks || '_',
        grade_as_percent: (assignment.max_marks && submission.grade) ? `${(submission.grade / assignment.max_marks) * 100}%` : '_',
        status: submissionStatusText(submission),
        loAchieved: submission?.status === 'pending' ? '' : objectivesThatDoNotNeedFeedback(assignment?.assignment_objectives, submission).reduce((result, objective) => `${result}(${objective.name})\n`, ''),
        loNotAchieved: submission?.status === 'pending' ? '' :  objectivesThatNeedsFeedback(assignment?.assignment_objectives, submission).reduce((result, objective) => `${result}(${objective.name})\n`, ''),
      }
    });

    setCsvItems(() => csvData);
  };

  useEffect(() => {
    const newSortableColumns = {
      name: sortableColumns?.name || '',
      confidence: sortableColumns?.confidence || '',
      studentGrade: sortableColumns?.studentGrade || '',
      submissionStatusText: sortableColumns?.submissionStatusText || '',
      learningObjectives: sortableColumns?.learningObjectives || '',
    };

    setSortableColumns(newSortableColumns);

    // Get an already sorted column instead default to name in asc order
    let sortedColumn = 'name'
    let sortedDirection = 'asc'
    for (const key in newSortableColumns) {
      if (newSortableColumns[key]) {
        sortedColumn = key
        sortedDirection = newSortableColumns[key]
      }
    }


    const baseSubmissions = addDefaults(assignment?.user_assignments || []);
    sortSubmissions(baseSubmissions, sortedColumn, newSortableColumns, sortedDirection)
  }, [assignment]);

  return (
    <div>
      {/* Pending Submissions */}

      {
        !!submissions.length &&
          <div className="mb-4">
            {/*<Header title="Students' submissions" subtitle="Find your students confidence scores and work for review here" />*/}
            <div className="card">
              <Fragment>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-sortable">
                      <thead>
                        <tr>
                          <th className={`sortable ${sortableColumns.name}`} onClick={() => sortSubmissions(submissions, 'name')} >Name</th>
                          <th className={`sortable ${sortableColumns.confidence}`} onClick={() => sortSubmissions(submissions, 'confidence')} style={{minWidth: '125px'}}>Confidence</th>
                          <th className={`sortable ${sortableColumns.studentGrade}`} onClick={() => sortSubmissions(submissions, 'studentGrade')} style={{minWidth: '85px'}}>Grade</th>
                          <th className={`sortable ${sortableColumns.submissionStatusText}`} onClick={() => sortSubmissions(submissions, 'submissionStatusText')}>Status</th>
                          {
                            assignment?.grade_display_to_students !== 'rubrics' &&
                              <th className={`sortable ${sortableColumns.learningObjectives}`} onClick={() => sortSubmissions(submissions, 'learningObjectives')} style={{minWidth: '120px'}}>Objectives</th>
                          }
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                      {
                        submissions.map((submission, index) => (
                          <tr>
                            {/*Name*/}
                            <td>
                              <div className="d-flex flex-wrap align-items-center">
                                <Avatar size="xs" img={submission?.user?.avatar_url} />
                                <div>{submission.name}</div>
                              </div>
                            </td>

                            {/*Confidence*/}
                            <td>
                              {
                                !!submission?.student_submission?.confidence.text ?
                                  <div className="d-flex align-items-center">
                                    <span className="me-2">{cleanConfidence(submission?.student_submission?.confidence.text)}</span>
                                    <Emoji level={submission?.student_submission?.confidence.level} />
                                  </div> : '-'
                              }
                            </td>

                            {/*Grade*/}
                            <td>{ submission.studentGrade }</td>

                            {/*Status*/}
                            <td><SubmissionBadge submission={submission} /></td>

                            {/*Objectives*/}
                            {
                              assignment?.grade_display_to_students !== 'rubrics' &&
                                <td>
                                  {
                                    assignment?.assignment_objectives.map((objective, index) => (
                                      <div className="avatar avatar-xs me-1" data-bs-toggle="tooltip" data-bs-placement="top" title={objective.name} key={objective.id}>
                                        <div className={`avatar-title font-size-xs rounded-circle ${learningObjectiveClass(submission, objective)}`}>
                                          {index + 1}
                                        </div>
                                      </div>
                                    ))
                                  }
                                </td>
                            }

                            <td>
                              <Button className="btn-sm"
                                      withLoader disabled={loading} loaderText="Loading"
                                      loading={loading && isCurrentSubmission(submission)} onClick={() => handleViewSubmission(submission)}>
                                Review
                              </Button>
                            </td>
                          </tr>
                        ))
                      }
                      </tbody>
                    </table>
                  </div>
                  <div className="d-flex justify-content-end mt-3">
                    <ExportJsonCsv className="btn btn-outline-nurture-purple btn-sm" headers={csvHeaders} items={csvItems} fileTitle={`${assignment?.name}_submissions`}>
                      Export as CSV
                    </ExportJsonCsv>
                  </div>
                </div>
              </Fragment>
            </div>
          </div>
      }

      <SubmissionModal submissions={submissions} currentSubmission={submissionData} assignment={assignment} show={showSubmissionModal} toggle={toggleSubmissionModal} />
    </div>
  );
};

Submissions.propTypes = {
  assignment: PropTypes.shape({}).isRequired,
};

export default Submissions;
