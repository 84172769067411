import "easymde/dist/easymde.min.css";
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from "react";
import SimpleMdeReact from "react-simplemde-editor";
import { autoEmbed, autolinker, markdownToHtml } from "core/utils";

const MarkdownEditorField = ({ className, initialValue, error, maxHeight, onChange, ...rest }) => {
  const [value, setValue] = useState('');

  const handleChange = (value) => {
    setValue(value);
    onChange(value);
  };

  const options = useMemo(() => {
    return {
      toolbar: [],
      previewRender: (content) => markdownToHtml(autolinker(autoEmbed(content))),
      spellChecker: false,
      maxHeight: maxHeight,
    }
  }, []);

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue]);

  return (
    <div className={`${error ? 'error' : ''}`}>
      <SimpleMdeReact
        options={options}
        className={className}
        value={value}
        placeholder="Write something here..."
        onChange={handleChange}
        {...rest}
      />
    </div>
  );
};

MarkdownEditorField.defaultProps = {
  error: false,
  initialValue: '',
  maxHeight: '200px'
};

MarkdownEditorField.propTypes = {
  initialValue: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  maxHeight: PropTypes.string,
};


export default MarkdownEditorField;
