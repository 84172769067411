import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { calculateProgress } from 'core/utils';
import './assignmentCard.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisV} from "@fortawesome/free-solid-svg-icons";
import {faEdit, faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import React, {useState} from "react";
import {DeleteModal} from "../modals";
import {useSnackbar} from "notistack";
import {useDispatch} from "react-redux";
import {assignmentDelete} from "modules/assignments/action";
import AssignmentDatesInfo from "modules/assignments/AssignmentDatesInfo";
import {useAssignmentInfo} from "../../hooks";

const AssignmentCard = ({ assignment, isDraft, isScheduled }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletingTemplate, setDeletingTemplate] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { progress } = assignment;
  const navigateTo = useNavigate();
  const sessionUser = JSON.parse(sessionStorage.getItem('user'));
  const role = sessionUser?.role;
  const isTeacher = role === 'teacher';

  const {
    submittedUserAssignments,
    reflectionUserAssignments,
    totalUserAssignments
  } = useAssignmentInfo(assignment);

  const assignmentProgress = isTeacher ? calculateProgress(progress?.submissions, progress?.assigned) : undefined;
  const dispatch = useDispatch();

  const navigateToEdit = () => navigateTo(`/assignments/${assignment.id}/edit`);

  const navigateToShow = () => navigateTo(`/assignments/${assignment.id}`);

  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);

  const handleAssignmentDelete = async () => {
    try {
      setDeletingTemplate(true);
      await dispatch(assignmentDelete(assignment.id, true));
      enqueueSnackbar('Assessment was deleted', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' })
    } finally {
      setDeletingTemplate(false);
      setShowDeleteModal(false);
    }
  }

  return (
    <div className="col-12">
      <div className="card mb-4 AssignmentCard">
        <div className="card-body p-0">
          <div className="row align-items-center p-3">
            {/*Left*/}
            <div className='col-xl-7 col-sm-12 mb-4 mb-xl-0'>
              <div className='font-size-20 font-weight-600 mb-2'>
                {assignment.name}
              </div>
              <div>
                <AssignmentDatesInfo assignment={assignment} color='#667085'/>
              </div>
            </div>

            <div className="row align-items-center col-xl-5 col-sm-12">
              {/*Middle*/}
              {
                isTeacher &&
                <div className="d-flex col-sm-6 mb-4 mb-sm-0">
                  {/*Students Sunmitted*/}
                  <div className="me-5 merriweather-font">
                    <div
                      className="font-size-24 font-weight-400 metrics-value">
                      {
                        (isDraft || isScheduled) ?
                          '-' :
                          `${submittedUserAssignments.length} / ${totalUserAssignments}`
                      }
                    </div>
                    <div className="small-texts metric-label">Submitted</div>
                  </div>
                  {/*Students Reflected*/}
                  <div className="merriweather-font">
                    <div
                      className="font-size-24 font-weight-400 metrics-value">
                      {
                        (isDraft || isScheduled) ?
                          '-' :
                          `${reflectionUserAssignments.length} / ${totalUserAssignments}`
                      }
                    </div>
                    <div className="small-texts metric-label">Reflected</div>
                  </div>
                </div>
              }

              {/*Right*/}
              <div className={`d-flex ms-xl-0 justify-content-sm-end ${isTeacher ? 'col-sm-6' : 'col-12'}`}>
                <div className="me-3">
                  <button className="btn new-purple-btn"
                          onClick={isDraft ? navigateToEdit : navigateToShow}>{isDraft ? 'Continue' : 'Open'}</button>
                </div>
                {
                  isTeacher &&
                  <div className="dropdown d-inline-block">
                    <button type="button" id={`options_${assignment?.id}`}
                            className="btn btn-outline-white text-black-50"
                            data-bs-toggle="dropdown">
                      <FontAwesomeIcon className="d-inline-block" icon={faEllipsisV}/>
                    </button>
                    <ul className="dropdown-menu border-none shadow-sm" aria-labelledby={`options_${assignment?.id}`}>
                      <li><a className="dropdown-item cursor-pointer" onClick={navigateToEdit}><FontAwesomeIcon
                        icon={faEdit} className="me-1"/>Edit</a></li>
                      <li><a className="dropdown-item cursor-pointer"
                             onClick={() => setShowDeleteModal(true)}><FontAwesomeIcon icon={faTrashAlt}
                                                                                       className="me-1"/>Delete</a></li>
                    </ul>
                  </div>
                }
              </div>
            </div>
          </div>
          {/*<div className="d-flex justify-content-between p-2 px-0">*/}
          {/*  <div className="flex-grow-1">*/}
          {/*    <AssignmentCardHeader user={user} date={created_at} className="py-4 px-4" />*/}
          {/*  </div>*/}
          {/*  {isTeacher && (*/}
          {/*      <div className="dropdown d-inline-block">*/}
          {/*        <button type="button" id={`options_${assignment?.id}`} className="btn btn-outline-white text-black-50"*/}
          {/*                data-bs-toggle="dropdown">*/}
          {/*          <FontAwesomeIcon className="d-inline-block" icon={faEllipsisV}/>*/}
          {/*        </button>*/}
          {/*        <ul className="dropdown-menu border-none shadow-sm" aria-labelledby={`options_${assignment?.id}`}>*/}
          {/*          <li><a className="dropdown-item cursor-pointer" onClick={navigateToEdit}><FontAwesomeIcon*/}
          {/*              icon={faEdit} className="me-1"/>Edit</a></li>*/}
          {/*          <li><a className="dropdown-item cursor-pointer"*/}
          {/*                 onClick={() => setShowDeleteModal(true)}><FontAwesomeIcon icon={faTrashAlt}*/}
          {/*                                                                           className="me-1"/>Delete</a></li>*/}
          {/*        </ul>*/}
          {/*      </div>*/}
          {/*    )*/}
          {/*  }*/}
          {/*</div>*/}

          {/*<div className="d-flex justify-content-between py-1 px-4">*/}
          {/*  <Link to={`assignments/${assignment.id}`} className="text-decoration-none">*/}
          {/*    <h6 className="mb-0 name">{name}</h6>*/}
          {/*  </Link>*/}
          {/*  <span className='custom-badge assignment-badge-fixed-width text-end'>*/}
          {/*    <span className={`${badge} rounded`}> {badgeText} </span>*/}
          {/*  </span>*/}
          {/*</div>*/}
          {/*<div className="px-4">*/}
          {/*  {due_at && (*/}
          {/*      <small*/}
          {/*          className={`${overdue(due_at) ? 'text-danger' : 'text-muted'} fw-lighter`}>Due {dateUtils.fromNow(due_at)}</small>*/}
          {/*  )*/}
          {/*  }*/}
          {/*</div>*/}

          {/*<div className="px-4 py-2 border-bottom mt-2">*/}
          {/*  <ReactMarkdown>{description && truncate({ string: description, length: 50 })}</ReactMarkdown>*/}
          {/*</div>*/}

          {/*{isTeacher && (*/}
          {/*  <div className="d-flex  align-items-center py-3 px-4">*/}
          {/*    <small className="text-muted fw-light d-block small-text">{progress?.submissions} submitted</small>*/}
          {/*    <ProgressBar value={assignmentProgress} />*/}
          {/*  </div>*/}
          {/*)}*/}

          {/*<div className="d-flex justify-content-center align-items-center py-3 px-4 border-top">*/}
          {/*  <Link to={`/assignments/${assignment.id}`} className="link-btn btn-md w-100 rounded">*/}
          {/*    View assessment*/}
          {/*  </Link>*/}
          {/*</div>*/}
        </div>
      </div >

      {
        isTeacher &&
        <DeleteModal
          show={showDeleteModal}
          onDelete={handleAssignmentDelete}
          toggle={toggleDeleteModal}
          text="Are you sure you want to delete this assessment?"
          submitting={deletingTemplate} />
      }

    </div>
  );
};

AssignmentCard.propType = {
  assignment: PropTypes.shape({}),
};

export default AssignmentCard;
