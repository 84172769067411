import NurtureLogo from 'assets/images/general_icons/nurture-logo.svg';
import GreyStarOnGraph from 'assets/images/general_icons/grey-star-on-graph.svg';
import ColorfulCheckInCircle from 'assets/images/general_icons/colorful-check-in-circle.svg';
import GreySettings from 'assets/images/general_icons/grey-settings.svg';
import GreyFlash from 'assets/images/general_icons/grey-flash.svg';
import ColorfulFlash from 'assets/images/general_icons/colorful-flash.svg';
import ColorfulStarOnGraph from 'assets/images/general_icons/colorful-star-on-graph.svg';
import GreyCheckInCircleOverlap from 'assets/images/general_icons/grey-check-in-circle-overlap.svg';
import WhiteRound from 'assets/images/general_icons/white_round.svg';
import './header-new.scss'
import { TABS } from "./Tab";
import {useSchoolConfig} from "../core/hooks";
import PropTypes from "prop-types";
import GreyQuestionMarkInCircle from 'assets/images/general_icons/grey-question-mark-in-circle.svg';
import RedLogout from 'assets/images/general_icons/red-logout.svg';
import React from "react";
import { deleteCache } from 'core/channel/action';
import { useDispatch } from 'react-redux';
import {envVariables, teamsEnv} from "../core/utils";
import {useSnackbar} from "notistack";

const HeaderNew = ({ activeTab, onTabChange, onNewAssignment }) => {
  const { trialEnded } = useSchoolConfig();
  const { tenantId, channelId } = teamsEnv;
  const user = JSON.parse(sessionStorage.getItem('user'));
  const isTeacher = user?.role === 'teacher';
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const logOut = () => {
    localStorage.removeItem('token');
    window.location.href = `${envVariables.apiBase}/i/teams/${tenantId}/${channelId}/logout`;
  }

  const handleTabClick = (tab) => {
    onTabChange(tab);
  };

  const handleRefreshCache = async () => {
    try {
      await dispatch(deleteCache());
      enqueueSnackbar('Users refreshed successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Error occurred while refreshing, Try again!', { variant: 'error' });
    }
  }

  return (
    <div className='HeaderNew'>
      <div className='HeaderNew-container'>
        {/*Left Side*/}
        <div className='left'>
          <img src={NurtureLogo} className='mb-2 mb-md-0' />

          {/*Nav Items*/}
          <nav className='d-md-flex'>
            <div
              className={`HeaderNew-nav ${activeTab === TABS.dashboard ? 'active' : ''}`}
              onClick={() => handleTabClick(TABS.dashboard)}
            >
              <img src={activeTab === TABS.dashboard ? ColorfulStarOnGraph : GreyStarOnGraph} alt="Dashboard" />
              <span className='ms-1'>Dashboard</span>
            </div>
            <div
              className={`HeaderNew-nav ${activeTab === TABS.assignments ? 'active' : ''}`}
              onClick={() => handleTabClick(TABS.assignments)}
            >
              <img src={activeTab === TABS.assignments ? ColorfulCheckInCircle : GreyCheckInCircleOverlap} alt="Assessments"/>
              <span className='ms-1'>Assessments</span>
            </div>

            {
              isTeacher &&
                <div
                  className={`HeaderNew-nav ${activeTab === TABS.assignments_templates ? 'active' : ''}`}
                  onClick={() => handleTabClick(TABS.assignments_templates)}
                >
                  <img src={activeTab === TABS.assignments_templates ? ColorfulFlash : GreyFlash} alt="Templates"/>
                  <span className='ms-1'>Templates</span>
                </div>
            }
          </nav>
        </div>

        {/*Right Side*/}
        <div className='right'>
          {
            isTeacher &&
            <button
              className={`btn new-purple-btn ${trialEnded ? 'pointer-events-none' : ''}`}
              onClick={onNewAssignment}
            >
              New Assessment
            </button>
          }

          {/*{*/}
          {/*  !isTeacher &&*/}
          {/*  <button*/}
          {/*    className={`btn new-purple-btn`}*/}
          {/*    onClick={logOut}*/}
          {/*  >*/}
          {/*    Logout*/}
          {/*  </button>*/}
          {/*}*/}

          <div
            className='nav-item settings'>
            <img src={GreySettings} alt="Settings" id="settingsDropdown"
                 data-bs-toggle="dropdown" aria-expanded="false"/>
            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="settingsDropdown">
              {/*Classroom settings and Support*/}
              {
                isTeacher &&
                <>
                  <li>
                    <div className="dropdown-item cursor-pointer font-size-14"
                         onClick={() => {
                           onTabChange('classroom_settings');
                         }}>
                      <img src={GreySettings} className="me-2"/> Classroom Settings
                    </div>
                  </li>
                  <li>
                    <div className="dropdown-item cursor-pointer font-size-14"
                         onClick={() => {
                           onTabChange('help_resources');
                         }}>
                      <img src={GreyQuestionMarkInCircle} className="me-2"/> Support
                    </div>
                  </li>
                  <li>
                    <hr className="dropdown-divider"/>
                  </li>
                </>
              }

              <li>
                <div className="dropdown-item cursor-pointer font-size-14"
                     onClick={handleRefreshCache}>
                  <img src={WhiteRound} className="me-2"/> Reload users
                </div>
              </li>

              <li>
                <hr className="dropdown-divider"/>
              </li>

              <li>
                <div className="dropdown-item cursor-pointer font-size-14"
                     onClick={logOut}>
                  <img src={RedLogout} className="me-2"/> Logout
                </div>
              </li>
            </ul>
          </div>

        </div>

      </div>
    </div>
  );
}

HeaderNew.defaultProps = {
  onTabChange: () => {
  },
  onNewAssignment: () => {
  },
}

HeaderNew.propTypes = {
  onTabChange: PropTypes.func,
  onNewAssignment: PropTypes.func,
  activeTab: PropTypes.string.isRequired
}

export default HeaderNew;