import * as api from './api';
import * as t from './actionTypes';
import { handleApiError } from "../utils";

export const verifyAuthTokenStart = () => ({
  type: t.AUTH_LOAD_START,
});

export const verifyAuthTokenSuccess = currentUser => ({
  type: t.AUTH_LOAD_SUCCESS,
  currentUser,
});

export const verifyAuthToken = (token, params) => async dispatch => {
  dispatch(verifyAuthTokenStart());
  // if (params.verify_token) sessionStorage.setItem('lastUsedToken', token);
  localStorage.setItem('token', token);
  try {
    const response = await api.verifyAuth(params);
    dispatch(verifyAuthTokenSuccess(response.data.current_user));

    localStorage.setItem('token', response.data.token);
    sessionStorage.setItem('user', JSON.stringify(response.data.current_user));
    return response.data.current_user;
  } catch (error) {
    // Dispatch a notification action here

    if (error === 'unauthorized') {
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('authenticated');
      sessionStorage.removeItem('user');
    }
    return Promise.reject(handleApiError(error));
  }
};

export const updateChannel = (payload) => async dispatch => {
  try {
    const response = await api.updateChannel(payload);
    dispatch(verifyAuthTokenSuccess(response.data.current_user));

    sessionStorage.setItem('user', JSON.stringify(response.data.current_user));
    return response.data.current_user;
  } catch (error) {
    return Promise.reject(handleApiError(error));
  }
}

export const teacherRequest = (payload) => async dispatch => {
  try {
    const response = await api.teacherRequest(payload);
    return response.data;
  } catch (error) {
    return Promise.reject(handleApiError(error));
  }
}

export const deleteCache = () => async dispatch => {
  try {
    const response = await api.deleteCache();
    window.location.reload();
    return response.data;
  } catch (error) {
    return Promise.reject(handleApiError(error));
  }
}
