import { useState } from "react";
import { Avatar, Header, TextHeader } from "core/components";
import { userFullName, pluralize, singularize } from "core/utils";
import { useSchoolConfig } from "core/hooks"
import FeedbackButton from "./FeedbackButton";
import FeedbackFooter from "./FeedbackFooter";
import PropTypes from "prop-types";
import GroupFeedbackModal from "./groupFeedback/GroupFeedbackModal";
import RubricTable from "./RubricTable";

const Feedbacks = ({ assignment }) => {
  const [assignmentObjective, setAssignmentObjective] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedObjectiveIndex, setSelectedObjectiveIndex] = useState();
  const objectives = assignment?.assignment_objectives || [];
  const { learningObjectiveLabel, rubricsEnabled } = useSchoolConfig();

  const handleGroupFeedback = (index, objective) => {
    setSelectedObjectiveIndex(index)
    setAssignmentObjective(objective);
    setShowModal(true);

  }

  const toggleModal = () => setShowModal(!showModal);

  return (
    <div className="mt-3">
      {/*<Header*/}
      {/*  title={`${learningObjectiveLabel} feedback`}*/}
      {/*  subtitle={`Create group ${learningObjectiveLabel.toLowerCase()} feedback that goes to the students who need feedback in that area.`}*/}
      {/*/>*/}
      <div className="card">
        <div className="card-body">
          {
            assignment.grade_display_to_students === 'rubrics' && rubricsEnabled &&
              <div className="m-0 m-sm-3">
                <RubricTable assignment={assignment} showStudents={true} />
              </div>
          }

          {
            assignment.grade_display_to_students !== 'rubrics' &&
              <>
                <div className="list-group list-group-flush my-n3">
                  {
                    objectives.map((objective, index) => (
                      <div className="list-group-item" key={index}>
                        <div className="row">
                          <div className="col-auto">
                            <div className="avatar avatar-sm">
                              <div className="avatar-title font-size-lg rounded-circle bg-primary-soft text-primary">
                                {index + 1}
                              </div>
                            </div>
                          </div>
                          <div className="col ms-n2">
                            <TextHeader>{objective.name}</TextHeader>
                            {
                              !!objective.attachments?.length &&
                              <div className="small text-muted mb-3 mt-n2">{objective.attachments.length} {pluralize('Attachment', objective.attachments.length, true)}</div>
                            }
                            <div className="avatar-group d-sm-flex">
                              {
                                !!objective.feedback_objectives?.length ?
                                  objective.feedback_objectives.map(feedback => {
                                    const user = feedback.user_assignment.user
                                    if (user != null) {
                                      return <Avatar img={user.avatar_url} size="xs" title={userFullName(user)} key={user.id} />
                                    }
                                  }) :
                                  <small className="text-muted">
                                    Students don't need feedback for this {singularize(learningObjectiveLabel).toLowerCase()}.
                                  </small>
                              }
                            </div>
                          </div>
                          {
                            assignment.feedback_type === 'group_feedback' &&
                            <FeedbackButton objective={objective} onClick={(objective) => handleGroupFeedback(index, objective)} />
                          }
                        </div>
                      </div>
                    ))
                  }
                </div>
                <GroupFeedbackModal selectedIndex={selectedObjectiveIndex} show={showModal} toggle={toggleModal} assignment={assignment} assignmentObjective={assignmentObjective} />
              </>
          }
        </div>
        <div className="card-footer">
          <FeedbackFooter assignment={assignment} />
        </div>
      </div>
    </div>
  );
}

Feedbacks.propTypes = {
  assignment: PropTypes.shape({}).isRequired
};

export default Feedbacks;