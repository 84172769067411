import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {isPresent, pluralize} from "core/utils";
import { maxBy } from "lodash";
import SectionHeader from "../../../modules/assignments/SectionHeader";

const RubricGradingTable = ({ assignment, submission, aiRubricGrades, setValue, onGraded }) => {
  const [rubricGrades, setRubricGrades] = useState([]);
  const [studentScore, setStudentScore] = useState(0);

  const { rubrics } = assignment
  const maxMark = rubrics.reduce((sum, item) => sum + maxBy(item.columns, 'point')?.point, 0);

  const isCellSelected = (assignmentObjectiveId, cellId) => {
    const row = rubricGrades.find(item => item.ms_teams_assignment_objective_id === assignmentObjectiveId);
    if (!row) return false

    return row.mst_rubric_cell_id === cellId
  }

  const handleSelect = (assignmentObjectiveId, cellId) => {
    setRubricGrades((prev) => {
      const result = [...prev]

      // Get current selected for the learning objective
      const rowIndex = result.findIndex(item => item.ms_teams_assignment_objective_id === assignmentObjectiveId);

      if (rowIndex !== -1) { // something is currently selected, replace the cell id
        result[rowIndex] = { ...result[rowIndex], mst_rubric_cell_id: cellId }
      } else {
        result.push({  ms_teams_assignment_objective_id: assignmentObjectiveId, mst_rubric_cell_id: cellId })
      }

      onGraded(result)
      return result
    });
  }

  useEffect(() => {
    setRubricGrades(submission.rubric_grades)
  }, [submission]);

  useEffect(() => {
    if (isPresent(aiRubricGrades)) {
      onGraded(aiRubricGrades, true)
      setRubricGrades(aiRubricGrades)
    }
  }, [aiRubricGrades]);

  useEffect(() => {
    let totalStudentScore = 0
    rubricGrades.forEach((rubricGrade) => {
      const rubric = assignment.rubrics.find((r) => r.id === rubricGrade.ms_teams_assignment_objective_id)
      const score = rubric.columns.find((c) => c.cell_id === rubricGrade.mst_rubric_cell_id).point

      totalStudentScore += score
    });

    setStudentScore(totalStudentScore);
    setValue('grade', totalStudentScore);
  }, [rubricGrades])

  return (
    <div>
      <div className="table-responsive">
        <table className="table table-bordered table-nurture-striped">
          <thead>
          <tr>
            <th style={{minWidth: '150px'}}>Learning Outcome</th>
            {
              rubrics[0].columns.map((column) => {
                return (
                  <th style={{minWidth: '150px'}} key={column.id}>
                    <div className="d-flex justify-content-between align-items-center">
                      <span>{column.name}</span>
                      <span
                        title={`${column.point} ${pluralize('point', column.point)}`}
                        className="badge p-2 bg-nurture-navy-blue text-dark ms-2 text-white">
                      { column.point } {pluralize('point', column.point)}
                    </span>
                    </div>
                  </th>
                )
              })
            }
          </tr>
          </thead>
          <tbody>
          {
            rubrics.map(rubric => {
              return (
                <tr>
                  <td>
                    { rubric['Learning Outcome'] }
                  </td>
                  {
                    rubric.columns.map(column => {
                      return <td
                        className={`selectable ${isCellSelected(rubric.id, column.cell_id) ? 'selected' : ''}`}
                        onClick={() => handleSelect(rubric.id, column.cell_id)}
                      >
                        <span>{column.value}</span>
                        <div className="form-check">
                          <input className="form-check-input cursor-pointer" type="checkbox" value="" checked={isCellSelected(rubric.id, column.cell_id)} />
                        </div>
                      </td>
                    })
                  }
                </tr>
              )
            })
          }
          </tbody>
        </table>
      </div>
      <div className='d-md-flex alert alert-light justify-content-md-between align-items-center mt-3'>
        <div className="d-flex flex-row">
          <div className="d-flex me-4 align-items-center mt-2">
            <h2>💎</h2>
          </div>
          <SectionHeader
            title='Student Score'
            subtitle='student assigned result for this assessment.'
            titleClassName="fs-5"
          />
        </div>

        <div
          className="fw-3 mb-0 mt-3 mt-md-0"
          style={{ minWidth: '14%' }}
        >
        <span
          className="custom-badge badge-soft-info fs-5 rounded px-2 py-1 text-center"
          style={{ width: '-webkit-fill-available' }}
        >
          <b>{studentScore}</b> out of <b>{maxMark}</b>
        </span>
        </div>
      </div>
    </div>
  );
};

RubricGradingTable.defaultProps = {

};

RubricGradingTable.propTypes = {
  assignment: PropTypes.shape({}).isRequired,
  submission: PropTypes.shape({}).isRequired,
  setValue: PropTypes.func.isRequired,
  onGraded: PropTypes.func.isRequired
};

export default RubricGradingTable;