import PropTypes from 'prop-types';
import { AssignmentSubcard } from 'core/components';
import SectionHeader from '../assignments/SectionHeader';

const descriptorText = score => {
  switch (true) {
    case (score >= 21 && score <= 39):
      return "Partially Achieved";
    case (score >= 40 && score <= 54):
      return "Achieved";
    case (score >= 55 && score <= 74):
      return "Merit";
    case (score >= 75 && score <= 89):
      return "Higher Merit";
    case (score >= 90 && score <= 100):
      return "Higher Merit";
    default:
      return "Not graded";
  }
};

const formatGradeType = ({ type, score, maxMarks }) => {
  const gradePercentage = Math.round(parseFloat((score / maxMarks) * 100));
  const gradeTypes = {
    rubrics: <><b>{score}</b> out of {maxMarks}</>,
    marks: <><b>{score}</b> out of {maxMarks}</>,
    percentage: `${gradePercentage}%`,
    descriptors: descriptorText(gradePercentage)
  };

  return gradeTypes[type] || gradeTypes['default'];
};

const Grade = ({ score, maxMarks, gradeType }) => (
  <AssignmentSubcard>
    <div className='d-md-flex justify-content-md-between align-items-center'>
      <div className="d-flex flex-row">
        <div className="d-flex me-4 align-items-center mt-2">
          <h2>💎</h2>
        </div>
        <SectionHeader
          title='Your Result'
          subtitle='Your assigned result for this assessment.'
          titleClassName="fs-5"
        />
      </div>

      <div
        className="fw-3 mb-0 mt-3 mt-md-0"
        style={{ minWidth: '14%' }}
      >
        <span
          className="custom-badge badge-soft-info fs-5 rounded px-2 py-1 text-center"
          style={{ width: '-webkit-fill-available' }}
        >
          {formatGradeType({ score, maxMarks, type: gradeType })}
        </span>
      </div>
    </div>
  </AssignmentSubcard>
);
Grade.defaultProps = {
  maxMarks: undefined,
  gradeType: '',
};

Grade.propTypes = {
  score: PropTypes.number.isRequired,
  maxMarks: PropTypes.number,
  gradeType: PropTypes.string,
};

export default Grade;
